import axios from "axios";
import Vue from "vue";
import store from ".";

export default {
    state: {
        gists: [],
    },

    getters: {
        getGistById: (state) => (id) => {
            return state.gists.find(gist => gist.id === id)
        },
    },

    actions: {
        fetchGists({ commit }) {
            axios.get('/api/gist')
                .then(response => {
                    commit('setGists', response.data);
                });
        },
        editGist({ getters }, id) {
            return axios.put('/api/gist/'+id, getters.getGistById(id))
            .then(res => {
                console.log(res.data)
                store.commit("updateModale", {content: "Le Gist a correctement été édité", color: "success"})
                return res.data;
            })
        },
        createGist({dispatch}, data){
            return axios.post('/api/gist', data)
            .then(res => {
                console.log("creategist res :"+res.data);
                if (res.data.status === "error") {
                    console.log("Error on create")
                    store.commit("updateModale", {content: res.data.statusMessage, color: "danger"})
                    return false;
                } else if(res.data.status === "ok") {
                    store.commit("updateModale", {content: "Le Gist a correctement été créé", color: "success"})
                    dispatch('fetchGists');
                    return true;
                }
            })
        },
        deleteGist({dispatch}, id){
            return axios.delete('/api/gist/'+id)
            .then(res => {
                console.log(res.data);
                if (res.data.status === "error") {
                    console.log("Error on create")
                    store.commit("updateModale", {content: res.data.statusMessage, color: "danger"})
                    return false;
                } else if(res.data.status === "ok") {
                    store.commit("updateModale", {content: "Le Gist a correctement été supprimé", color: "success"})
                    dispatch('fetchGists');
                    return true;
                }
            })

        }

    },

    mutations: {
        setGists(state, gists) {
            state.gists = gists;
        },
        updateGistContent(state, {id, content}) {
            const index = state.gists.findIndex(gist => gist.id === id);
            if (index !== -1) {
                Vue.set(state.gists[index], 'content', content)
            }

        }
    },
};
