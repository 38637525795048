import Vue from "vue";

export default {
    state: {
        auth: null,
    },

    getters: {
        // getGistById: (state) => (id) => {
        //     return state.gists.find(gist => gist.id === id)
        // },
        getAuthed: state => {
            return !(state.auth == null || state.auth == "")
          }
    },

    actions: {
        fetchAuth({ commit }) {
            axios.get('/api/auth')
                .then(response => {
                    commit('setAuth', response.data);
                });
        },
        // editGist({ getters }, id) {
        //     return axios.put('/api/gist/'+id, getters.getGistById(id))
        //     .then(res => {
        //         console.log(res.data)
        //         return res.data;
        //     })
        // }

    },

    mutations: {
        setAuth(state, auth) {
            console.log(auth);
            state.auth = auth;
        },
        // updateGistContent(state, {id, content}) {
        //     const index = state.gists.findIndex(gist => gist.id === id);
        //     if (index !== -1) {
        //         Vue.set(state.gists[index], 'content', content)
        //     }
        // }
    },
};
