import Vue from "vue";

export default {
    state: {
        show: false,
        content: "",
        color: ""
    },

    getters: {
        getMessage: (state) => {
            return state.message;
        },
        getColor: (state) => {
            return state.message;
        },
    },

    actions: {
    },

    mutations: {
        updateModale(state, data) {
            console.log("Update Modale")
            state.content = data.content;
            state.color = data.color;
            state.show = true;
        },
        displayModale({state, mutations}){
            state.show = true;
        },
        hideModale(state){
            state.show = false;
        }
    },
};
