<template>

    <div class="card gist-item">
        <div class="card-header d-flex justify-content-between">
            <a :href="gist.filename" target="_blank">{{ gist.filename }}</a>
            <div>
                <span class="" v-on:click="editable = !editable" v-if="authed">Edit</span>
                <span class="" v-on:click="deleteConfirmation" v-if="authed">Delete</span>
            </div>
        </div>

        <div class="card-body">

            <template v-if="!editable">
                <p class="code" v-html="formatedMark"></p>
            </template>
            <template v-else>
                <textarea class="code" v-model="content"></textarea>
            </template>


        </div>
    </div>
</template>

<script>
    const marked = require("marked");
    import store from "../store";

    export default {
        data: function () {
            return {
                editable: false,
            }
        },
        computed: {
            formatedMark: function () {
                return marked(this.content);
            },
            content: {
                get () { return store.getters.getGistById(this.id).content},
                set (val) { store.commit('updateGistContent', {id: this.id, content: val} ) }
            },
            gist: function() {
                return store.getters.getGistById(this.id);
            },
            authed: () => {return store.getters.getAuthed}
        },
        watch: {
            editable: function (val) {
                console.log("Watch editable : "+val)
                if (!val) {
                    store.dispatch('editGist', this.id)
                }
            },
        },
        props: ['id'],
        methods: {
            deleteConfirmation() {
                if(confirm("Êtes vous sûr ?")){
                    console.log(this.id);
                    store.dispatch("deleteGist", this.id)
                }
            },
        }
    }
</script>
