<template>
    <div class="container">

        <button class="btn btn--primary mx-auto" @click="$refs.addGist.openModal()" v-if="authed">Ajouter</button>

        <modal></modal>

        <gist-form ref="addGist">

        </gist-form>

        <div class="row justify-content-center">
            <div class="col-md-8">
                <gist
                v-for="gist in gists"
                v-bind:key="gist.id"
                v-bind:id="gist.id"
                ></gist>

            </div>
        </div>
    </div>
</template>

<script>
    import store from "../store";
    export default {
        computed: {
            gists() {return store.state.gist.gists;},
            authed: () => {return store.getters.getAuthed}
        },
        created() {
            store.dispatch('fetchGists')
            store.dispatch('fetchAuth')
        },
        methods: {
            showModal() {
                store.commit("updateModale", {content: "Bjr", color: "red"})
            },
        }
    }
</script>
