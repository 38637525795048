<template>
    <transition name="fade">
        <!-- <div id="alert-modal" class="modal" v-if="show" :style="userStyle">
            <div class="modal-dialog" tabindex="-1" role="document">
                <div class="modal-content">
                    <div id="alert-modal-body" class="modal-body">{{ content }}</div>
                </div>
            </div>
        </div> -->
        <div id="alert-modal" class="modal" v-if="show" :style="userStyle">
            <div class="alert" :class="alertType">
                <strong>{{ content }}</strong>
            </div>
        </div>
    </transition>
</template>

<script>
import store from "../store";

export default {
    computed: {
        content () { return store.state.modal.content },
        color () { return store.state.modal.color },
        show () { return store.state.modal.show },
        userStyle () {
            return {
                'background-color' : this.color
            }
        },
        alertType() {
            return "alert-"+this.color;
        },
    },
    methods: {
        closeModal() {
            store.commit("displayModale")
        },
        openModal() {
            store.commit("hideModale")
        },
    },
    watch: {
        show: function(val) {
            if (val){
                setTimeout(() => { store.commit("hideModale") }, 5000);
            }
        }
    },
    mounted() {

    },
    beforeDestroy() {

    }

}
</script>


<style lang="scss" scoped>
.modal {
    position: fixed;
    top: 25px;
    right: unset;
    bottom: unset;
    left: 50%;
    z-index: 9;
    display: block;
    width: auto;
    height: unset;
}
// #alert-modal-body {
//     // background-color: var(--bg-color);
// }
.fade-enter-active, .fade-leave-active {
    transition: top .5s;
}
.fade-enter, .fade-leave-to {
    top: -50px;
}
</style>
