<template>
    <div class="modal" v-if="show">
        <div class="modal-dialog modal-lg" tabindex="-1" role="document">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Modal title</h5>
                <button type="button" class="close" @click="closeModal()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <label for="inputNameGist">Nom du gist :</label>
                    <input id="inputNameGist" class="form-control" v-model="gist.name">
                </div>
                <div class="form-group">
                    <label for="inputContentGist">Contenu :</label>
                    <textarea id="inputContentGist" class="form-control code" v-model="gist.content" />
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn--secondary" @click="closeModal()">Cancel</button>
                <button class="btn btn--primary" @click="saveGist()">Save</button>
            </div>
            </div>
        </div>

    </div>

</template>

<script>
import store from "../store";

export default {
    data() {
        return {
            show: false,
            gist: {
                name: "",
                content: "",
            }
        };
    },
    methods: {
        closeModal() {
            this.show = false;
            document.querySelector("body").classList.remove("overflow-hidden");
        },
        openModal() {
            this.show = true;
            document.querySelector("body").classList.add("overflow-hidden");
        },
        saveGist() {
            if (this.gist.name !== '') {
                store.dispatch("createGist", this.gist)
                .then((res) => {
                    if (res){
                        this.closeModal();
                        this.gist.name = "";
                        this.gist.content = "";
                    }
                });
            } else {
                store.commit("updateModale", {content: "Le nom ne peut pas être vide", color: "red"})
            }
        }
    }
}
</script>


<style lang="scss" scoped>
.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    overflow-x: hidden;
    overflow-y: auto;
    display: block;
    background: rgba(0,0,0,0.3);
}
</style>
